$primary: #1677ff
$shadow: #000
$white: #fff
$border: #ececec
$danger: #ff4d4f

.estimate
  // max-width: 100dvw

.estimate-header
  // display: grid
  position: sticky
  top: 0
  background-color: #fff
  z-index: 10
  border-bottom: 1px solid #ddd
  box-shadow: 0px 4px 1rem rgba($shadow, 0.04)
  &__row
    height: 40px
    // display: flex
    background: $white
  &__cell
    padding: 2px 8px
    outline: 1px solid $border
    background: $white
    line-height: 110%
    &--fixed
      background: $white
      z-index: 9 !important
    > div
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      line-height: 120%
.estimate-body
  &__row
    min-height: 28px !important
    // display: flex
    align-items: center
    align-content: center
    @for $i from 1 through 10
      &-expanded-level-#{$i}
        background: darken($white,$i * 1.5)
        .estimate-body__cell--fixed
          background: darken($white,$i * 1.5)
        .estimate-body__cell:first-child
          padding: 4px 8px 4px calc($i/0.8)+rem
          justify-content: start
      &:hover
        .estimate-body__cell
          background-color: lighten($primary,40) !important
          cursor: pointer
        .app-wbs-move-controls
          opacity: 1
    &-expanded-level-0
      background: #fff
      .estimate-body__cell:first-child
        padding: 4px 8px 4px 0.8rem
        justify-content: start
    &--sticky
      position: sticky
      top: 74.5px
      z-index: 9 !important
      box-shadow: 0 6px 12px rgba(0,0,0,0.04)
    &--copy, &--copy.estimate-body__row
      .estimate-body__cell
        background: lighten($primary,35) !important
    &--work
      .estimate-body__cell
        background: #e7e7e7
        &:first-child
          font-weight: 500
          color: #000
    &--danger
      .estimate-body__cell
        background: lighten($danger,28)
      &:hover
        .estimate-body__cell
          background: lighten($danger,25) !important
  &__cell
    outline: 1px solid $border
    height: 100%
    align-items: center
    justify-content: center
    text-align: center
    background: $white
    p
      width: 100%
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      margin: 0
    &--fixed
      background: $white
      div
        white-space: normal !important

.estimate-row-expanded
  cursor: pointer
  &:hover
    background-color: lighten($primary,40 )

.estimate-position-title
  width: 100%
  text-align: left

.estimate-position-input
  background: rgba($white,0.5)
  border-radius: 0
  border: 1px solid lighten($primary,30)
  width: 100%
  &:hover
    background: rgba($white,0.25)
    box-shadow: inset 0px 0px 4px 2px rgba($primary,0.15)
  &:focus
    background: $white !important
    box-shadow: inset 0px 0px 4px 2px rgba($primary,0.15)
  input
    text-align: center !important

.resizer
  background: rgba(0, 0, 0, 0.5)
  cursor: col-resize
  height: 100%
  position: absolute
  right: 0
  top: 0
  touch-action: none
  user-select: none
  width: 5px

.resizer.isResizing
  background: blue
  opacity: 1

.ellipsis-block
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  word-break: keep-all
  line-height: 120%

.tooltip-wide
  max-width: 1200px
