$primary: #1677ff
$danger: #ff4d4f
$success: #52c41a
$gray: #dddddd
$white: #ffffff
$dark: #444
$mainBG: linear-gradient(180deg, #2E323F 0%, #0A061A 100%)
$contentBG: rgba($white,0.90)
$shadow: rgba($dark, 0.08)
$scroll: #204381
$border: #cfd6f5

$status_open: #FFAD14
$status_draft: #9DAEAF
$status_completed: #0770C5
$status_inspect: #7A77D9
$status_danger: #FF142D
$status_void: #8099A8
$status_author: #d9ab77
$status_dp: #77d6d9
$status_author_dp: #d977a5

$checklist-stage: #0061bf
$checklist-section: #0096bf
$checklist-section-item: #00bfa6

body
    padding: 0
    margin: 0

*
    ::-webkit-scrollbar
        width: 8px
        height: 8px
    ::-webkit-scrollbar-track
        background-color: transparent
    ::-webkit-scrollbar-thumb
        background-color: $scroll
        border: 1px solid transparent
        border-radius: 12px
        background-clip: content-box
img, svg
    max-width: 100%
    height: auto

.wrapper
    width: 100%
    min-height: 100vh
    display: flex

.at-jcc
    justify-content: center
.at-aic
    align-items: center
.at-jce
    justify-content: end
.at-jcsb
    justify-content: space-between
.at-fxdc
    display: flex
    flex-direction: column
.at-gap
    gap: 1rem

.at-bg
    display: flex
    justify-content: center
    align-items: center
    max-width: 1440px
    width: 100%
    height: 70vh
    background: url('/assets/at_bg.png') no-repeat center center
    background-size: contain

.at-col-vertical
    flex-direction: column

.at-block
    padding: 4px
    border: 1px solid $gray
    display: flex
    align-items: center
    justify-content: center
    min-height: 30px

.at-btn
    display: flex
    align-items: center
    justify-content: center
    padding: .5rem
    height: auto
    border-radius: 8px
    &--fill
        width: 100%
    &--select
        text-align: left
        justify-content: flex-start
        // white-space: break-spaces
        width: 100%
        .anticon
            flex: 0 0 30px !important
        span:last-child
            overflow: hidden
            width: 100%
            flex: 1 0 0%
            text-overflow: ellipsis

.at-btn-wrap span
    white-space: pre-wrap
    text-align: left

.at-btn-menu
    background: #383C48
    border-radius: 6px
    border: none
    padding: 6px
    *
        color: #fff
    &:hover, &:focus
        background: $primary !important

.at-icon
    transition: 0.25s
    svg
        width: 3rem
        padding: 0.2rem
    &--spin
        animation: 30s linear 1s infinite at-spin

@keyframes at-spin
    from
        transform: rotate(0deg)
    to
        transform: rotate(360deg)

.at-widget
    display: flex
    flex-direction: column
    background: $mainBG
    overflow: hidden
    box-shadow: 0 0 1rem rgba($dark, 0.2)
    padding: 1rem
    color: $white
    border-radius: 0.5rem
    transition: 0.25s
    max-width: 100vw
    height: 100%
    width: 100%
    &__header
        font-size: 1.2rem
        padding-bottom: 0.5rem
        display: flex
        flex: 0 0 0
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid rgba($white, .2)
        button
            border: none
            padding: 0
            svg
                fill: $white
    &__body
        flex: 1 0 auto
        display: flex
        align-items: center
        *
            color: $white !important
        &--loading
            filter: blur(4px)
    &__col
        display: flex
        align-items: center
.at-input
    padding: 0.5rem
    border-radius: 8px

.dashboard
    display: flex
    flex-direction: column
    width: 100%
    height: 100vh
    max-height: 100vh
    background: $mainBG

.dashboard__header
    padding: 1rem
    display: flex
    align-items: center
    gap: 1rem
    &-logo
        flex: 0 0 200px
        cursor: pointer
    .ant-breadcrumb, .ant-breadcrumb-separator
        color: #D6E1F5
        a
            color: #D6E1F5
            &:hover
                text-decoration: underline
        li:last-child, li:last-child a
            color: #ebf0fa

.dashboard__body
    flex: 1 1 0%
    flex-direction: column
    display: flex
    // flex-wrap: wrap
    background: $contentBG
    overflow-y: scroll
    overflow-x: clip
    padding: 1rem
    gap: 8px
    align-items: normal

.at-flex
    display: flex

.at-loader
    display: flex
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    left: 0
    z-index: 999
    background: rgba(0,0,0,.05)
    backdrop-filter: blur(4px)

.dashboard__block, .checklists__block
    border-radius: 0.8rem
    gap: 8px
    max-width: 100%
    flex: 1 0 auto
    background: #f2f2f2
    padding: 1rem
    box-shadow: 2px 4px 12px rgba(98,98,98,0.08)

.at-tac .ant-tooltip-inner
    text-align: center

.at-modal--dark
    .ant-modal-header
        background: #2E323F
        padding: 1rem
        *
            color: $white
    .ant-modal-content
        overflow: hidden
        padding: 0
    .ant-modal-close-icon path
        fill: $white

.at-menu
    padding: 1rem
    width: 500px
    border-radius: .5rem
    background: $white
    // box-shadow: 2px 4px 12px rgba($dark, 0.2)
    &__title
        font-weight: bold
        font-size: 1.5rem
        position: relative
        padding-left: 1rem
        white-space: break-spaces
        &::before
            position: absolute
            content: ''
            top: 0
            left: 0
            height: 100%
            width: 4px
            background: $primary
    &__grid
        margin-top: 1rem
    &__item
        border: 1px solid $white
        background: rgba($white,.8)
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        padding: .5rem
        border-radius: 1rem
        flex: 1 0 45%
        text-align: center
        height: 120px
        transition: 0.25s
        color: #555d77
        width: 100%
        min-width: 128px
        height: 132px
        padding: 1rem
        &-icon
            flex: 1
            display: flex
            flex-direction: column
            justify-content: end
            padding-bottom: 0.5rem
        &-title
            flex: 1
            white-space: break-spaces
        &:hover
            box-shadow: 0 0 1rem rgba($dark,.2)
            svg path
            fill: $primary
    &__list
        display: flex
        flex-direction: column
        max-height: 500px
        overflow-y: scroll
        gap: 6px
    &__footer
        border-top: 1px solid $gray
        padding-top: 1rem
        margin-top: 1rem
    .active
        box-shadow: 0 0 1rem rgba($dark,.2)
        color: $primary
        svg path
        fill: $primary
    .ant-space
        width: 100% !important

.split
    display: flex
    flex-direction: row
    width: 100%
    background: #f2f2f2
    box-shadow: 2px 4px 12px rgba(98,98,98,0.08)
    &__left
        min-width: 25%
        // flex-grow: 1
        padding: 1rem
    &__right
        min-width: 35%
        padding: 1rem

.gutter
    background-color: #e9eaeb
    background-repeat: no-repeat
    background-position: 50%

.gutter.gutter-horizontal
    background-image: url('data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')
    cursor: col-resize

.at-graybox
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border-radius: 4px
    margin: auto
    background: lighten($gray, 7)

.ant-table-expanded-row > .ant-table-cell:not(:has(.at-umd-inner))
    display: none

.ant-table-cell:has(>.at-umd-inner)
    padding: 0 !important

.at-umd-inner
    padding: 0rem
    .ant-table
        margin: 0 !important

#wrapper
    // height: calc(100vh - 150px)
    overflow: auto
.at-tblrow
    width: 100%
    cursor: pointer
    .ant-table-cell:first-child
        border-left: 4px solid transparent !important
    &:hover > *
        background: lighten($primary,35)
    &--blocked, &--blocked:hover > *
        cursor: not-allowed
        background: lighten($gray,10) !important
        .ant-table-cell:first-child
            border-left: 4px solid $dark !important
    &--ready
        background: lighten($gray,10) !important
        .ant-table-cell:first-child
            border-left: 4px solid $success !important
    &--active
        background: lighten($primary, 42) !important
    &--include
        color: $primary
    &--expanded
        .ant-table-expanded-row-fixed
            padding: 0 !important
.ant-modal-mask
    backdrop-filter: blur(8px)

.app-entity-card
    .ant-drawer-content-wrapper.ant-drawer-panel-motion-right-leave
        filter: brightness(100)

.ant-modal-wrap
    display: flex
    align-items: center
    justify-content: center

.ant-modal
    top: 0

.at-tabs
    > .ant-tabs-nav
        margin-bottom: 0
    .ant-tabs-tab
        border-radius: 6px 6px 0 0 !important
        overflow: hidden
        background: #dfdfdf !important
    .ant-tabs-tab-active
        background: $primary !important
        *
            color: $white !important

.at-workcard
    box-shadow: 0 0 8px rgba($dark, 0.1)
    .ant-form-item:last-child
        margin-bottom: 0
    &:not(:first-child)
        margin-top: 1rem

.at-estimate-table
    *
        scroll-behavior: smooth
    .ant-checkbox-disabled
        display: none
    .ant-table-cell-with-append
        display: flex
        flex-wrap: no-wrap
        align-items: center
        span[aria-label]
            margin-right: 8px
    .ant-table-summary td
        background: lighten($primary, 43) !important

.at-row-level-1
    cursor: pointer

#ordinance-fine-drawer-header
    &--new .ant-select-selector
        background-color: #FFAD14
    &--collected .ant-select-selector
        background-color: #7A77D9
    &--canceled .ant-select-selector
        background-color: #9DAEAF

#ordinance-fine-drawer-header
    &--new .ant-btn-default
        background-color: #FFAD14
    &--collected .ant-btn-default
        background-color: #7A77D9
    &--canceled .ant-btn-default
        background-color: #9DAEAF

#ordinance-fine-drawer-header
    &--new
        background-color: #FFAD14
    &--collected
        background-color: #7A77D9
    &--canceled
        background-color: #9DAEAF

.at-issue-drawer
    * > .ant-drawer-header
        transition: all .25s linear
    &--open .ant-drawer-header
        background-color: $status_open
    &--draft .ant-drawer-header
        background-color: $status_draft
    &--completed .ant-drawer-header
        background-color: $status_completed
    &--inspect .ant-drawer-header
        background-color: $status_inspect
    &--danger .ant-drawer-header
        background-color: $status_danger
    &--void .ant-drawer-header
        background-color: $status_void
    .ant-drawer-header
        *
            color: $white
        h2
            margin: 0
        .ant-select .ant-select-selector
            background-color: transparent !important
    .ant-drawer-header-title
        align-items: flex-start
    .ant-btn-ghost[disabled], .ant-btn-ghost[disabled]:hover, .ant-btn-ghost[disabled]:focus, .ant-btn-ghost[disabled]:active
        background-color: rgba($dark,.3)

.at-descriptions
    .ant-select-selector, .ant-input-affix-wrapper
        border-radius: 0 !important
    .ant-select-selector, input, textarea, .ant-input-affix-wrapper
        border: none !important
    .ant-picker, .ant-input-affix-wrapper
        border: none !important
        // padding-left: 0 !important

.ant-descriptions-item-content > span > p
    padding: 4px 4px 4px 10px !important

.at-issue-row
    cursor: pointer
    &--disabled > *
        background-color: rgba($danger, .1) !important

.at-drawer-status
    .ant-drawer-header
        padding-top: 2rem
        padding-bottom: 2rem
        *
            color: $white !important
    &--noneliminated .ant-drawer-header
        background-color: $status_draft
    &--eliminated .ant-drawer-header
        background-color: $status_completed

.at-drawer-notifications
    .ant-drawer-header
        padding-top: 2rem
        padding-bottom: 2rem
        background: #1677ff
        *
            color: $white !important
    .ant-drawer-body, .ant-drawer-footer
        background: #ebebeb

.at-drawer-color-text
    .ant-drawer-header
        *
            color: $white !important

.at-status-indicator
    &--open
        background-color: $status_open
    &--new
        background-color: $status_open
    &--draft
        background-color: $status_draft
    &--canceled
        background-color: $status_draft
    &--completed
        background-color: $status_completed
    &--inspect
        background-color: $status_inspect
    &--collected
        background-color: $status_inspect
    &--danger
        background-color: $status_danger
    &--void
        background-color: $status_void
    &--exhibited
        background-color: $status_danger
    &--disabled
        background-color: rgba($danger, .1)

.at-issues-activity
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot, .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot
        background: $gray
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after
        background-color: $gray

.at-issues-comment
    .ant-comment-content-author
        justify-content: space-between
    .ant-comment-content-author-time
        color: $dark

.at-searchblock
    .anticon-search
        padding: 6px

.at-status-indicator
    width: 6px
    height: 100%
    max-height: 24px
    min-height: 24px
    // background-color: $primary
    border-radius: 2px

.at-drawer-left .ant-drawer-header-title
    flex-direction: row-reverse

.at-homegrid
    &__row
        height: 100%
    &__cell
        display: flex
        align-items: center
        justify-content: center
        min-height: 200px

.at-table-column--hide
    display: none

.at-checklist-descriptions
    .ant-form-item
        width: 100%
        align-items: center
    label
        white-space: pre-wrap !important
        height: auto !important

.at-expandable-table
    *:not(button)
        border-radius: 0 !important
    .ant-form-item
        margin-bottom: 6px

.app-btn-cover
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    border: none
    box-shadow: inset 2px 4px 12px rgba(0,0,0,0.5)

.app-table-types
    .ant-table-cell
        vertical-align: top
    .ant-table-row-level-2 > .ant-table-cell:nth-child(2), .ant-table-row-level-1 > .ant-table-cell:nth-child(2)
        padding-left: 8px !important
    .ant-table-row-level-1 td
        background-color: #f7f7f7
.column-title-tooltip span
    display: block

//ANTD
.ant-col
    white-space: break-spaces

.ant-tabs-content > *, .ant-tabs-content-holder > *
    height: 100%

// .ant-table-row-level-1
//     background: darken($white,3)
//     & > .ant-table-cell:nth-child(2)
//         padding-left: 2rem !important

// .ant-table-row-level-2
//     background: darken($white,7)
//     & > .ant-table-cell:nth-child(2)
//         padding-left: 3rem !important

// .ant-table-row-level-3
    // background: darken($white,8)
    // & > .ant-table-cell:nth-child(2)
    //     padding-left: 4rem !important

// .ant-table-row-level-4
//     background: darken($white,9)
//     & > .ant-table-cell:nth-child(2)
//         padding-left: 5rem !important

.ant-message
    display: flex
    flex-direction: column
    align-items: flex-start
    z-index: 1000010000000

//resizebleTable
#resizable-table
    .react-resizable
        position: relative
        background-clip: padding-box
    .react-resizable-handle
        position: absolute
        right: -10px
        bottom: 0
        z-index: 1
        width: 20px
        height: 100%
        cursor: col-resize
    table
        table-layout: fixed
    td, th
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.ant-table-thead
    -moz-user-select: none
    -o-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none

.ant-form-item
    display: grid

.ant-image-preview-img
    height: 80vh

.ant-form-item-has-error textarea
    border-color: $danger

.ant-descriptions-item-content .ant-form-item-control-input-content
    display: flex
    align-items: center

*[class$="disabled"]:not([class*="tab"]), *[class$="disabled"] *:not([class*="tab"]), ~[class$="disabled"]:not([class*="ant-picker-cell"])
    color: $dark !important
    background-color: darken($white, 2) !important
    .ant-select-arrow
        display: none

.ant-table-cell-fix-left-last
    box-shadow: 3px 0 0 rgba($dark,.1)

#checklistForm .ant-form-item
    margin-bottom: 0
#checklistForm th
    background: #f0f0f0
#checklistFormDescription th
    padding-top: 8px
    padding-bottom: 8px

#templateFormDescriptions .ant-descriptions-header, #templateFormDescriptions .ant-form-item
    margin-bottom: 0

.ant-table-wrapper .ant-table-container table>thead>tr:first-child th:first-child, .ant-table-wrapper .ant-table-container table>thead>tr:first-child th:last-child
    border-radius: 0 !important

// .ant-table-cell-fix-left,.ant-table-cell-fix-right
//     background-color: rgba($white,.8) !important
//     backdrop-filter: blur(4px)

.remove-after .ant-row .ant-col label:after
    display: none

.checkbox-yk-col-title
    writing-mode: tb-rl
    transform: rotate(-180deg)
    padding: 0 !important

.section-name-col-title
    writing-mode: tb-rl
    transform: rotate(-180deg)

.light-report-table .ant-table-header
    border-radius: 0 !important
.light-report-table .ant-table-body::-webkit-scrollbar
    width: 8px
    height: 8px
.light-report-table table
    border-top: 1px solid black
    border-radius: 0 !important

.light-report-table th
    border: 1px black solid !important
    background-color: #e0e0e0 !important
    padding: 0 !important
    position: relative
.light-report-table td
    padding: 0 !important
    position: relative
.light-report-table td div
    width: 100%
    height: 100%
    margin: 0

.light-report-table-issues th
    background-color: #e0e0e0 !important
.light-report-table-issues thead tr:last-child th
    background-color: #efefef !important

.checklist-attachments-area-wrapper .ant-descriptions-item-container .ant-descriptions-item-content
    display: block

.section-issues-table thead th
    padding: 4px 16px !important
.section-issues-table tbody td
    padding: 4px 8px !important

.report-table
    .ant-table-body
        .ant-table-row:hover > td:not(.ant-table-cell-fix-left)
            background-color: rgba($primary, 0.15) !important
    ::-webkit-scrollbar
        height: 8px !important
    ::-webkit-scrollbar-track
        background: #f2f2f2
    thead th, .antd-table-custom-class tbody td
        white-space: pre-wrap
        text-overflow: ellipsis

.report-table-sticky
    max-width: 30rem
    position: sticky
    top: 0
    left: 350px
    display: flex
    justify-content: center
    align-items: flex-start
    height: 2rem
    > *
        position: absolute
        top: 0
        left: 0
        display: flex
        align-items: center
        height: 100%

.at-tabs-sticky
    width: 100%
    min-height: 100%
    .ant-tabs-nav
        position: sticky
        top: 0
        z-index: 99
        background: $white
        padding: 0 1rem
        box-shadow: 0 4px 12px $shadow
    .ant-tabs-content-holder
        padding: 0 1rem 1rem

.at-row-blur
    filter: blur(2px)

.at-row-highlight
    z-index: 9999 !important
    background-color: $primary !important
    color: #fff !important
    transition: background-color .2s

.at-row-dndend
    background-color: #ddd

.at-image-wrapper
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    border-radius: 0.5rem
    background: #f2f2f2
    img
        max-width: 90%
        width: auto !important

.at-table-top td
    vertical-align: top

.at-expandable
    > .ant-table-cell
        display: table-cell !important

.at-traffic-column-total:nth-child(1n)
    background-color: rgba(darken($primary, 20), 0.1)
    border: 1px solid rgba($primary, 0.1)
.at-traffic-column-total:nth-child(2n)
    background-color: rgba(darken($primary, 25), 0.1)
    border: 1px solid rgba($primary, 0.1)

.at-traffic-column-even
    background-color: rgba($primary, 0.05)
    border: 1px solid rgba($primary, 0.08)

.at-table-noindent
    .ant-table-row td:last-child
        padding-left: 8px !important

.ant-image-preview-wrap
    z-index: 99998 !important

.ant-image-preview-mask
    z-index: 99997 !important

.ant-image-preview-operations-wrapper
    z-index: 99999 !important

.ant-modal-mask
    z-index: 99998
.ant-modal-wrap
    z-index: 99999

.ant-select-dropdown
    z-index: 1000000 !important

.app-selector-no-flow
    .ant-select-item-option-selected
        background-color: #fff !important
        .anticon
            display: none !important

.app-hoc-table
    .ant-table-thead th
        background: darken($primary,20) !important
        color: #fff !important
        font-weight: bold
.app-wbs-status-table
    .ant-table-expanded-row > .ant-table-cell
        display: table-cell !important
    .ant-table-thead .ant-table-row-expand-icon-cell
        background: darken($primary,20) !important
.app-wbs-status-table-expanded
    .ant-table
        margin: 0 !important
    .ant-table-thead th
        background: #7cb3ff !important
        color: #fff !important
        font-weight: bold
.app-wbs-status-table-expanded-row
    &.ant-table-row-level-0
        font-weight: normal !important
.app-wbs-status-table-row
    &.ant-table-row-level-0
        background: #e7e7e7 !important
.app-hoc-row
    &.ant-table-row-level-0
        background: lighten($primary,40) !important
        font-weight: bold
        .ant-table-cell-row-hover
            background: lighten($primary,30) !important
.ant-picker-dropdown
    z-index: 1000001 !important
// .at-select-no-deleted
//     .ant-select-item-option-disabled
//         display: none !important

.editable-cell
    display: inline-block
    width: 100px
    cursor: pointer
    border: 1px solid #DCDCDC
    border-radius: 5px
    input
        border: 1px solid #D6E1F5 !important
        width: 100%
        height: 100%
        &:focus
            outline: none
            border: 1px solid #D6E1F5 !important

.editable-cell-input
    display: inline-block
    width: 100px
    cursor: pointer
    input
        text-align: center
        border: 1px solid #D6E1F5 !important
        border-radius: 5px
        width: 100%
        height: 100%
        &:focus
            outline: none
            border: 1px solid #D6E1F5 !important

.editable-cell-input-error
    display: inline-block
    width: 100px
    cursor: pointer
    input
        text-align: center
        border: 1px solid red !important
        border-radius: 5px
        width: 100%
        height: 100%
        &:focus
            outline: none
            border: 1px solid red !important

.asm-card-drawer
    input[disabled], textarea[disabled], .ant-select-disabled .ant-select-selector, .ant-input-affix-wrapper-disabled
        background-color: #fff !important
        color: #444 !important
    .ant-input-number-group-addon
        color: #444 !important

.app-collapse
    .ant-collapse-item
        width: 100%

.app-table-odd
    thbody
    tr
        &:nth-child(even)
            td
                background-color: #f9f9f9
        &:hover td
            background-color: #f5f5f5 !important

.nomination-checkbox.ant-checkbox-wrapper
    .ant-checkbox
        display: inline-flex !important

.app-wbs-table
    position: relative
    .ant-table-sticky-scroll
        display: none !important
    .ant-table-body
        td,tr,th
            border-color: #dedede !important
    .ant-checkbox-disabled
        display: none
    .ant-table-cell-with-append
        padding-bottom: 0 !important
        padding-top: 0 !important
        .app-wbs-table__cell
            width: 100%
            display: inline-flex
            flex-wrap: nowrap
            align-items: center
            flex: 1
        span[aria-label="folder"], span[aria-label="folder-open"], span[aria-label="appstore"], span[aria-label="build"]
            margin-right: 8px
    .ant-table-summary td
        background: lighten($primary, 43) !important
    .ant-table-selection-column
        padding: 0 !important
    ::-webkit-scrollbar-thumb
        background-color: rgba($scroll, 0.2)
        &:hover
            background-color: rgba($scroll, 0.5)
    &--height
        .ant-table-body
            min-height: 37lvh !important
            padding-bottom: 0 !important
            @media screen and (min-height: 650px)
                min-height: 42lvh !important
            @media screen and (min-height: 750px)
                min-height: 53lvh !important
            @media screen and (min-height: 900px)
                min-height: 57lvh !important
            @media screen and (min-height: 1000px)
                min-height: 62lvh !important
            @media screen and (min-height: 1050px)
                min-height: 66lvh !important
            @media screen and (min-height: 1100px)
                min-height: 70lvh !important
            @media screen and (min-height: 1200px)
                min-height: 72lvh !important
    &__row_2_level
        height: 34px !important
        td
            background: #e7e7e7 !important
            div
                .ant-row
                    color: #000 !important
        &:hover
            td
                background: lighten($primary, 32) !important
            .app-wbs-move-controls
                opacity: 1
    &__row
        height: 34px !important
        padding-bottom: 0 !important
        padding-top: 0 !important
        &:hover
            td
                background: lighten($primary, 40) !important
            .app-wbs-move-controls
                opacity: 1
        &--copied
            td,tr
                background: lighten($primary, 38) !important
            &:hover
                td
                    background: lighten($primary, 40) !important
                .app-wbs-move-controls
                    opacity: 1
        &--sticky
            height: 34px !important
            position: sticky
            top: 0
            z-index: 99 !important
            box-shadow: 0 -6px 20px #fafafa63
            background: $white
            &:hover
                td
                    background: lighten($primary, 40) !important
                .app-wbs-move-controls
                    opacity: 1
        &--danger
            td,tr
                background: lighten($danger, 28) !important
            &:hover
                td,tr
                    background: lighten($danger, 25) !important
                .app-wbs-move-controls
                    opacity: 1
    @for $i from 1 through 10
        .ant-table-row-level-#{$i}, .ant-table-row-level-#{$i} > .ant-table-cell-fix-left
            background: darken($white,$i * 1.5)
    &--on-top
        .ant-table-cell
            vertical-align: top
.app-wbs-move-controls
    opacity: 0
    transition: 0.25s

.table-wbs-header
    .ant-table-header
        border-radius: 0 !important
    .ant-table-row-level-1, .ant-table-row-level-1 > .ant-table-cell-fix-left, .ant-table-row-level-1 > .ant-table-cell-fix-right
        background: darken($white,5)

.ant-dropdown
    z-index: 999999 !important

.app-table
    &__row
        &:hover
            td
                background: lighten($status_inspect, 27) !important
    @for $i from 1 through 10
        .ant-table-row-level-#{$i}, .ant-table-row-level-#{$i} > .ant-table-cell-fix-left
            background: darken($white,$i * 2)
    .ant-table-row-level-0
        background: lighten($status_inspect, 30)

button[data-attr="issueLink"]
    width: 100%
    text-align: left
    span
        text-overflow: ellipsis
        width: 100%
        overflow: hidden

button[data-attr="link"]
    width: 100%
    padding: 0
    margin: 0
    text-align: left
    span
        text-overflow: ellipsis
        width: 100%
        overflow: hidden

span[data-attr="link"]
    text-overflow: ellipsis
    width: 100%
    overflow: hidden

.app-wbs-table-height-VOR
    .ant-table-cell
        padding-bottom: 0 !important
        padding-top: 0 !important

.app-wbs-table .ant-table-summary td
    height: 34px !important
    background: #fff !important
    border-color: #dedede !important

.app-registry-col-status
    display: flex
    gap: 8px
    width: 100%
    height: 100%

.app-drawer
    &-header
        transition: 0.25s
        .ant-select-selector
            background-color: transparent !important
            color: #fff !important
        .ant-btn
            background-color: transparent !important
            color: #fff !important
        .ant-drawer-close, .ant-drawer-title
            color: #fff
        .ant-drawer-close
            transition: 0.25s
            &:hover
                color: #fff
                transform: rotate(180deg)

        &--new
            background: #feb300
        &--canceled
            background: #8099A8
        &--collected
            background: #7A77D9
        &--exhibited
            background: $status_danger
    &-form-item
        margin: 0
        &__status label
            font-size: 16px !important
            color: #fff !important

.app-btn-break
    span
        text-align: left
        white-space: normal
        word-break: break-word

.ant-table-tbody
    .app-checkList-table__row_1_level
        tr, td
            background: #e7e7e7 !important

.rowIconHistory
    span
        width: 20px
        height: 20px

.app-description-selector .ant-select-selector
    padding: 0 !important

.related-documents-table
    .ant-table-header
        tr, td
            .ant-table-cell-scrollbar
                display: none !important

.step-status
    min-width: 100px
    .ant-steps-item-container
        display: flex
        align-items: center
    .ant-steps-item-content
        white-space: normal
        word-break: break-word
        flex: 1
        @media screen and (max-width: 1190px)
            white-space: nowrap
            overflow: hidden
            padding: 5px
            text-overflow: ellipsis
    .ant-steps-item-title
        line-height: 16px !important
    &:last-child
        flex: 1 !important

.app-drawer-descriptions
    // .ant-descriptions-view
    //     border: none !important
    .ant-descriptions-row
        transition: 0.25s
        &:nth-child(even)
            background-color: #f9f9f9
        &:hover
            background-color: lighten($primary,42)
    .ant-descriptions-item-content
        padding: 0px 0px 0px 16px !important
    .ant-form-item
        margin-bottom: 0
    .ant-form-item-label
        font-weight: bold
        border-right: 1px solid rgba(5, 5, 5, 0.06)
        white-space: normal
        padding: 4px 8px 4px 2px !important
    .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
        display: contents !important
        content: '* '
    .ant-form-item-label >label::after
        display: contents !important
    .ant-form-item-control
        // padding-left: 8px
    .ant-form-item-row
        flex-flow: row nowrap
    // .ant-select-selector
    //     border: none !important
    .ant-select-selection-item
        width: 0
    .app-form-text
        padding: 0 11px
        // display: inline-block
        &.danger
            color: $danger
    .ant-select-disabled
        background: #efefef
.app-work-card
    box-shadow: 2px 4px 12px rgba(0,0,0, 0.08)
    border-radius: 12px
    // &__body
    //     padding: 0 !important
.app-tabs-sticky
    width: 100%
    min-height: 100%
    >.ant-tabs-nav
        position: sticky
        top: 0
        z-index: 99
        background: $white
        padding: .5rem 1rem 0
        box-shadow: 0 4px 12px $shadow
    >.ant-tabs-content-holder
        padding: 0 1rem 1rem

.app-entity-card__header
    .ant-drawer-header-title > *, label
        color: #fff !important
    &--closed, &--inprogress, &--void
        background-color: $status_void
    &--ready, &--warranty
        background-color: $status_inspect
    &--open, &--new
        background-color: $status_open
    &--cancel, &--canceled
        background-color: $status_danger
    &--completed
        background-color: $status_completed

.app-entity-card__body
    background: '#fafafa'
    padding: 0
    .ant-form-item-control
        display: flex
        justify-content: center
        overflow: hidden
    .ant-form-item-control-input
        padding-top: 4px
        padding-bottom: 4px
    .ant-form-item-label > label
        height: auto !important
    .ant-form-item-explain-error
        padding: 0 0 4px 11px
    .ant-select-disabled, .ant-select-selector, .ant-picker-disabled, .ant-input-disabled
        background-color: transparent !important
        *
            color: #444 !important
.issue-file-cursor
    cursor: zoom-in

.indicator
    width: 6px
    height: 100%
    max-height: 24px
    min-height: 24px
    border-radius: 2px
    &--New
        background-color: $status_open
    &--DP
        background-color: $status_draft
    &--DES
        background-color: $status_inspect
    &--Approved
        background-color: $status_completed
    &--AdditionalRequestAuthor
        background-color: $status_author
    &--AdditionalRequestDP
        background-color: $status_dp
    &--AdditionalRequestAuthorDP
        background-color: $status_author_dp
    &--Waiting
        background-color: $status_void

.issue-select-status
    div.ant-select-selector
        background-color: transparent !important
        color: $white
    .ant-select-arrow
        color: $white

.at-drawer-status
    .ant-drawer-header
        .borderForOrdinance
            padding: 5px
            color: red !important
            background-color: lighten(red, 40%)
            border: 1px solid lighten(red, 20%)
            border-radius: 4px
            font-weight: 450

.wbsTaskFieldBasis
    .ant-form-item-control-input-content
        justify-content: space-between

.ordinanceModalDocument
    .ant-table-thead
        display: none

.monitoringFilters
    .ant-form-item
        align-content: center !important
        margin: 0 !important

.main-rating-table
    .ant-table-container
        border: none !important
        .ant-table-thead >tr>th
            background-color: transparent !important
            color: 'black'
            border-bottom: 1px solid black !important
            border-right: none !important
            border-left: none !important
            line-height: 1 !important
        .ant-table-thead tr th
            padding-left: 0 !important 
            font-size: 0.6vw
        .ant-table-tbody >tr>td
            border-right: none !important
            border-left: none !important
            height: 39px !important
            font-size: 14px
        .ant-table-tbody tr
            width: 726px !important
            &:first-child
                display: none !important
        .ant-table-tbody
            .main-rating-table-row td
                padding: 1px 1px 8px 1px !important

.main-rating-table 
    .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body
        scrollbar-width: thin !important

.tableContainer
    .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body
        scrollbar-width: thin !important

.main-issue-type-table
    .ant-table-container
        border: none !important
        .ant-table-thead >tr>th
            background-color: transparent !important
            color: 'black'
        .ant-table-tbody >tr>td
            border: none !important
        .ant-table-tbody >tr>td::after
            content: ''
            position: absolute
            bottom: 0
            left: 0
            right: 0
            border-bottom: 1px solid #f0f0f0 !important
            margin-right: 3px
            margin-left: 3px
        .ant-table-tbody 
            .main-issue-type-table-row td
                padding: 6px 1px 7px 1px !important
                height: 28px
    .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body
        scrollbar-width: thin !important

.app-column-config-card
    box-shadow: 2px 4px 12px rgba(0,0,0,0.08)
    &__header
        border-bottom: 1px solid #ddd !important
        margin-bottom: 0 !important
    &__body
        padding: 0 0 .5rem !important
    .ant-dropdown-menu
        box-shadow: none

.app-report-table
    .ant-table-thead th:last-child
        display: none !important
        border: 0
    .ant-table-thead th:nth-last-child(2)
        border-radius: 0px 8px 0px 0px

.tableContainer
    .ant-table-sticky-scroll
        display: none !important

.ant-table-tbody
    td:has(div.issueTypeSum)
        background-color: lighten(grey, 40%)

.main-card .ant-card-body
    padding: 14px !important

.filter-main .ant-select-selector
    background-color: transparent !important
    color: #02152b
    border-color: #AFB3BB !important

.switch-period-main
    height: 14px !important
    .ant-switch-handle
        height: 10px !important
        width: 10px !important
.switch-period-main:where(.css-dev-only-do-not-override-1okl62o).ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle
        inset-inline-start: calc(100% - 12px) !important

.main-calendar>:first-child
    height: 18px
    align-items: center
    span>:first-child
        margin-left: 24px
    span>:last-child
        margin-right: 24px
    div
        font-weight: 400 !important
.main-calendar>:last-child
    .ant-picker-date-panel .ant-picker-body .ant-picker-content 
        thead tr
            line-height: 16px !important
            th
                padding-bottom: 6px !important
                font-size: 14px !important
        tbody tr
            line-height: 15px !important
            td
                font-size: 16px !important
            div.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today
                &::before
                    border-color: black
            .ant-picker-cell
                color: #878787
            .ant-picker-cell.ant-picker-cell-in-view
                color: #000000
            .ant-picker-cell.ant-picker-cell-selected.ant-picker-cell-in-view
                .ant-picker-cell-inner.ant-picker-calendar-date
                    background: black